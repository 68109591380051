import * as React from "react";
import "./Footer.sass";
import logo from "../images/logo_alt.png";
import { Link } from "gatsby";
import { telephonePrest, meetingPrest } from "../helpers";

const Footer = () => {
  return (
    <footer>
      <div id="footer-row-1">
        <div id="footer-col-1">
          <img src={logo} alt="logo"></img>
          <p>Rue des Alpes 15 </p>
          <p>1201 Genève</p>
          <p>info@legalify.ch</p>
          <p>022 731 40 31</p>
        </div>
        <div id="footer-col-2">
          <ul>
            <li className="title">Services spécialisés</li>
            <li>
              <Link to="/domaines/constitution-societes">Votre entreprise - droit des sociétés
              </Link>
            </li>
            <li>
              <Link to="/domaines/vos-contrats">
              Vos contrats et documents - droit des contrats
              </Link>
            </li>
            <li>
              <Link to="/domaines/ressources-humaines">
              Vos ressources humaines - droit du travail
              </Link>
            </li>
            <li>
              <Link to="/domaines/locaux">Vos locaux - droit du bail</Link>
            </li>
            <li>
              <Link to="/domaines/debiteurs">Vos débiteurs - recouvrement de créances</Link>
            </li>
          </ul>
          <ul>
            <li className="title" id="title-spacer">
              &nbsp;
            </li>
            <li>
              <Link to="/domaines/propriete-intellectuelle">
              Votre propriété intellectuelle - droit d'auteur - marques
              </Link>
            </li>
            <li>
              <Link to="/domaines/immobilier">Votre immobilier - droit immobilier </Link>
            </li>
            <li>
              <Link to="/domaines/constructions">Vos constructions - droit de la construction</Link>
            </li>
            <li>
              <Link to="/domaines/creanciers">Vos créanciers - poursuites et faillitess</Link>
            </li>
            <li>
              <Link to="/domaines/contentieux">Votre contentieux - procédures judiciaires</Link>
            </li>
          </ul>
          <ul>
            <li className="title">Prestations</li>
            <li>
              <Link to="/checkout" state={telephonePrest()}>
                Entretien téléphonique
              </Link>
            </li>
            <li>
              <Link to="/checkout" state={meetingPrest()}>
              Entretien en personne
              </Link>
            </li>
            <li>
              <Link to="/domaines/abonnement">Abonnements juridiques</Link>
            </li>
          </ul>
          <ul>
            <li className="title">À propos</li>
            <li>
              <Link to="/apropos">Legalify</Link>
            </li>
            <li>
              <Link to="/nous-trouver">Nous trouver</Link>
            </li>
          </ul>
        </div>
      </div>
      <div id="footer-row-2">
        <p>
          <span id="cities">Genève • Lausanne • Neuchâtel • Fribourg</span>
          <Link to="/cg">
            <span>Conditions générales</span>
          </Link>
          <Link to="/pdc">
            <span>Confidentialité</span>
          </Link>
          <span>2022 Legalify.ch. Tous droits réservés</span>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
