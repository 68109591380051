const helpers = {};

// holds and returns all contracts
export const getContract = (cid) => {
  const contracts = [
    {
      title: "Contrat de travail",
      subtitle:
        "Déterminez les relations de travail applicables entre employeur et employé en les arrêtant dans un contrat détaillé et précis. Téléchargez un contrat de travail en format .doc (word), ainsi qu'une note explicative détaillée.",
      checklist: ["Contrat de travail", "Note explicative détaillée"],
      price: 150,
      description:
        "Ce contrat règle les modalités des relations de travail soumises au droit suisse entre un employeur et un employé. Les éléments principaux du contrat qui doivent être définis par les parties sont : // - la durée du contrat, // - le temps de travail (temps plein ou temps partiel), // - les horaires à effectuer, // - la rémunération (salaire, bonus, commissions, etc.), // - les vacances et les congés, // - les modalités en cas d'empêchement de travailler, // - la référence à une convention collective de travail si nécessaire, // - les modalités de résiliation du contrat. // Notre contrat de travail est fourni en format .doc (word), ce qui vous permet de l'adapter selon vos besoins. Une note explicative détaillée fournie avec le contrat permet de comprendre les passages du document qui dépendent du choix des parties et de les compléter de manière spécifique et adaptée selon la situation.",
      cta: "Télécharger un contrat de travail",
      cid: "cdp29",
    },
    {
      title: "Contrat de sous-location",
      subtitle:
        "Déterminez les conditions applicables à une sous-location en les arrêtant dans un contrat détaillé et précis et effectuez l'annonce de la sous-location à votre bailleur. Téléchargez un contrat de sous-location en format .doc (word), ainsi qu'une note explicative détaillée et un modèle d'annonce.",
      checklist: [
        "Contrat de sous-location",
        "Note explicative détaillée",
        "Annonce au bailleur",
      ],
      price: 50,
      description:
        "Ce contrat règle les modalités d'une sous-location d'un bien immobilier entre un locataire initial (sous-bailleur) qui souhaite sous-louer ses locaux à un sous-locataire. Les éléments principaux du contrat qui doivent être définis par les parties sont : // - l'objet du contrat (les locaux à sous-louer), // - la durée de la sous-location, // - les possibilités de renouvellement (durée fixe ou indéterminée), // - le montant du loyer et des charges, // - les modalités de remise des locaux, // - les conditions de résiliation de la sous-location. // Notre contrat de sous-location est fourni en format .doc (word), ce qui vous permet de l'adapter selon vos besoins. Une note explicative détaillée fournie avec le contrat permet de comprendre les passages du document qui dépendent du choix des parties et de les compléter de manière spécifique et adaptée selon la situation. Un modèle de courrier d'annonce à votre bailleur en format .doc (word) vous est également fourni.",
      cta: "Télécharger un contrat de sous-location",
      cid: "cdsl30",
    },
    {
      title: "Mise en demeure",
      subtitle:
        "Effectuez la mise en demeure de votre débiteur lorsque celui-ci refuse de vous payer. Envoyez un courrier de mise en demeure pour faire partir les intérêts moratoires et entamer le processus de recouvrement de votre créance. Téléchargez un courrier de mise en demeure en format .doc (word), ainsi qu'une note explicative détaillée.",
      checklist: ["Courrier de mise en demeure", "Note explicative détaillée"],
      price: 30,
      description:
        "Un courrier de mise en demeure permet de signifier de manière formelle à un débiteur que celui-ci doit payer le(s) montant(s) qu'il doit à son créancier. Dans certains cas, il permet de fixer le point de départ des intérêts moratoires auxquels la loi donne droit, en principe à un taux de 5%. Les éléments principaux qui doivent figurer dans le courrier sont : // - le nom ou la raison sociale du débiteur, // - l'adresse exacte du débiteur, // - le(s) montant(s) à payer, // - les causes de la créance, // - le délai de paiement. // - les instructions de paiement. // Notre courrier de mise en demeure est fourni en format .doc (word), ce qui vous permet de l'adapter selon vos besoins. Une note explicative détaillée fournie avec le courrier permet de comprendre les passages du document qui dépendent de votre choix et de les compléter de manière spécifique et adaptée selon la situation.",
      cta: "Télécharger un courrier de mise en demeure",
      cid: "md31",
    },
    {
      title: "Contrat de prêt",
      subtitle:
        "Sécurisez votre prêt en prévoyant ses modalités dans un contrat détaillé et précis. Téléchargez un contrat de prêt en format .doc (word), ainsi qu'une note explicative détaillée.",
      checklist: ["Contrat de prêt", "Note explicative détaillée"],
      price: 100,
      description:
        "Ce contrat règle les modalités d'un prêt standard soumis au droit suisse. Les éléments principaux du contrat qui doivent être définis par les parties sont : // - le montant du prêt en capital, // - le versement d'intérêts sur le montant emprunté, // - la constitution de sûretés, // - les modalités de remboursement du prêt, // - les causes de défaut de l'emprunteur, // - le versement d'intérêts moratoires en cas de retard, // - les modalités de résiliation anticipée du contrat. // Notre contrat de prêt est fourni en format .doc (word), ce qui vous permet de l'adapter selon vos besoins. Une note explicative détaillée fournie avec le contrat permet de comprendre les passages du document qui dépendent du choix des parties et de les compléter de manière spécifique et adaptée selon la situation.",
      cta: "Télécharger un contrat de prêt",
      cid: "cdp01",
    },
    {
      title: "Convention d'actionnaires",
      subtitle:
        "Déterminez les relations applicables entre actionnaires d'une société anonyme en les arrêtant dans un contrat détaillé et précis. Prévoyez des objectifs communs, des modalités de vote et des clauses réglant le transfert d'actions selon vos besoins. Téléchargez une convention d'actionnaires en format .doc (word), ainsi qu'une note explicative détaillée.",
      checklist: ["Convention d'actionnaires", "Note explicative détaillée"],
      price: 200,
      description:
        "Cette convention définit les relations entre tout ou partie des actionnaires d'une société anonyme et vise à définir des objectifs communs, ainsi que certaines modalités visant à sauvegarder les intérêts des actionnaires concernés, par exemple des conventions de vote ou des clauses protégeant le transfert d'actions. Les éléments principaux de la convention qui doivent être définis par les parties sont : // - les objectifs des actionnaires, // - les modalités pour accéder au conseil d'administration, // - les modalités de vote lors des assemblées générales, // - les modalités de vote lors des décisions du conseil d'administration, // - les conditions applicables aux transferts d'actions (droit de préemption, transfert conjoint, etc), // - la durée de la convention, // - les conditions de résiliation. // Notre convention d'actionnaires est fourni en format .doc (word), ce qui vous permet de l'adapter selon vos besoins. Une note explicative détaillée fournie avec la convention permet de comprendre les passages du document qui dépendent du choix des parties et de les compléter de manière spécifique et adaptée selon la situation.",
      cta: "Télécharger une convention d'actionnaires",
      cid: "cda01",
    },
    {
      title: "Procuration",
      subtitle:
        "Obtenez une procuration configurable selon vos besoins et déterminez les tâches que vous souhaitez déléguer à une autre personne. Téléchargez gratuitement une procuration en format .doc (word), ainsi qu'une note explicative détaillée.",
      checklist: ["Procuration", "Note explicative détaillée"],
      price: "Gratuit",
      description:
        "Une procuration vise à transmettre temporairement certains droits à une autre personne, afin que celle-ci agisse en votre nom et effectue les tâches que vous ne pouvez pas ou ne voulez pas exécuter. Il peut s'agir, par exemple, d'effectuer des démarches auprès d'une autorité ou d'une entité privée, telle qu'une banque. Son avantage est qu'elle permet de déléguer l'exécution de certaines affaires, courantes ou inattendues, à une personne mieux à même de s'en occuper. // Une procuration peut faite dans un cadre professionnel, par exemple à un architecte ou à un avocat, ou dans un cadre privé, par exemple à un proche ou à une personne de confiance. // Une procuration peut être retirée en tout temps par la personne qui l'a accordée, selon sa propre appréciation. // Les éléments principaux qui doivent figurer dans la procuration sont : // - le nom ou la raison sociale de la personne qui accorde la procuration, // - le nom ou la raison sociale du mandataire, // - le cadre de la procuration, // - les tâches que le mandataire peut effectuer, // - les conditions d'exécution (personnelle ou avec l'aide de tiers). // Notre procuration est fournie en format .doc (word), ce qui vous permet de l'adapter selon vos besoins. Une note explicative détaillée fournie avec la procuration permet de comprendre les passages du document qui dépendent de votre choix et de les compléter de manière spécifique et adaptée selon la situation.",
      cta: "Télécharger une procuration",
      cid: "proc02",
    },
    {
      title: "Convocation d'assemblée générale",
      subtitle:
        "Convoquez l'assemblée générale de votre société anonyme en respectant les dispositions légales applicables. Téléchargez un courrier de convocation en format .doc (word), ainsi qu'une note explicative détaillée.",
      checklist: [
        "Convocation d'assemblée générale",
        "Note explicative détaillée",
      ],
      price: 50,
      description:
        "Une convocation d'assemblée générale doit impérativement respecter certaines obligations légales, sous peine de voir ses résultats être nuls ou annulables. La convocation doit être envoyée au moins 20 jours avant la date de l'assemblée, selon le mode prévu par les statuts. Il est donc impératif de vérifier ce que prévoient les statuts de votre société. En pratique, il est fréquent que les convocations d'assemblées de sociétés anonymes doivent être effectuées par courrier recommandé. // Les éléments principaux qui doivent figurer dans la convocation sont : // - le nom ou la raison sociale de l'actionnaire, // - l'adresse de l'actionnaire, // - le lieu, l'heure et la date de l'assemblée générale, // - les points à l'ordre du jour. // Notre convocation d'assemblée générale est fournie en format .doc (word), ce qui vous permet de l'adapter selon vos besoins. Une note explicative détaillée fournie avec la convocation permet de comprendre les passages du document qui dépendent de votre choix et de les compléter de manière spécifique et adaptée selon la situation.",
      cta: "Télécharger une convocation d'assemblée générale",
      cid: "conv02",
    },
    {
      title: "Statuts de société anonyme",
      subtitle:
        "Obtenez des statuts pour votre société anonyme. Téléchargez des statuts de société anonyme en format .doc (word), ainsi qu'une note explicative détaillée.",
      checklist: ["Statuts de société anonyme", "Note explicative détaillée"],
      price: 100,
      description:
        "Les statuts constituent un document obligatoire et fondamental d'une société anonyme. Ils déterminent les éléments essentiels de la société (raison sociale, siège, but et capital-actions), les droits et obligations des actionnaires (annonces et transferts d'actions, droits de vote, droit au bénéfice) et l'organisation de la société et son fonctionnement (assemblée générale, conseil d'administration, organe de révision, comptes de la société, dividendes, publications). Les statuts permettent aux fondateurs d'une société au moment de sa création, ou à ses actionnaires par la suite, de déterminer les règles applicables à la société. Il est donc essentiel de les rédiger de manière détaillée et réfléchie. Nos statuts sont fournis en format .doc (word), ce qui vous permet de les adapter selon vos besoins. Une note explicative détaillée fournie avec les statuts permet de comprendre les passages du document qui dépendent de votre choix et de les compléter de manière spécifique et adaptée selon la situation.",
      cta: "Télécharger des statuts de SA",
      cid: "sta03",
    },
    {
      title: "Registre des actions nominatives (SA)",
      subtitle:
        "Tenez le registre des actions nominatives de votre société anonyme selon les exigences légales. Téléchargez gratuitement un registre en format .doc (word), ainsi qu'une note explicative détaillée.",
      checklist: [
        "Registre des actions nominatives (SA)",
        "Note explicative détaillée",
      ],
      price: "Gratuit",
      description:
        "Chaque société anonyme est obligée, de par la loi, de tenir un registre de ses actions nominatives. Ce document doit mentionner le nom et l'adresse des propriétaires et des usufruitiers d'actions nominatives. Pour y être inscrit, un propriétaire ou un usufruitier doit pouvoir prouver qu'il a effectivement acquis un droit de propriété ou d'usufruit sur les actions et fournir des pièces justificatives à cet égard. La société est tenue d'exiger ces pièces et de les conserver par la suite dix ans après la radiation du propriétaire ou de l'usufruitier du registre. Notre registre est fourni en format .doc (word), ce qui vous permet de l'adapter selon vos besoins. Une note explicative détaillée fournie avec le registre permet de comprendre les passages du document qui dépendent de votre choix et de les compléter de manière spécifique et adaptée selon la situation.",
      cta: "Télécharger un registre des actions de SA",
      cid: "rga04",
    },
    {
      title: "Statuts de société à responsabilité limitée",
      subtitle:
        "Obtenez des statuts pour votre société à responsabilité limitée. Téléchargez des statuts de société à responsabilité limitée en format .doc (word), ainsi qu'une note explicative détaillée.",
      checklist: [
        "Statuts de société à responsabilité limitée",
        "Note explicative détaillée",
      ],
      price: 100,
      description:
        "Les statuts constituent un document obligatoire et fondamental d'une société à responsabilité limitée. Ils déterminent les éléments essentiels de la société (raison sociale, siège, but et capital social), les droits et obligations des associés (annonces et transferts de parts sociales, droits de vote, droit au bénéfice) et l'organisation de la société et son fonctionnement (assemblée générale, attributions des gérants, organe de révision, comptes de la société, dividendes, publications). Les statuts permettent aux fondateurs au moment de la création d'une société, ou à ses associés par la suite, de déterminer les règles qui lui seront applicables. Il est donc essentiel de les rédiger de manière détaillée et réfléchie. Nos statuts sont fournis en format .doc (word), ce qui vous permet de les adapter selon vos besoins. Une note explicative détaillée fournie avec les statuts permet de comprendre les passages du document qui dépendent de votre choix et de les compléter de manière spécifique et adaptée selon la situation.",
      cta: "Télécharger des statuts de Sàrl",
      cid: "starl04",
    },
    {
      title: "Registre des parts sociales (Sàrl)",
      subtitle:
        "Tenez le registre des parts sociales de votre société à responsabilité limitée selon les exigences légales. Téléchargez gratuitement un registre en format .doc (word), ainsi qu'une note explicative détaillée.",
      checklist: [
        "Registre des parts sociales (Sàrl)",
        "Note explicative détaillée",
      ],
      price: "Gratuit",
      description:
        "Chaque société à responsabilité limitée est obligée, de par la loi, de tenir un registre de ses parts sociales. Ce document doit mentionner le nom et l'adresse des associés et des usufruitiers de parts sociales, ainsi que le nombre et la valeur nominale des parts détenues par chacun. Pour y être inscrit, un associé ou un usufruitier doit pouvoir prouver qu'il a effectivement acquis un droit de propriété ou d'usufruit sur les parts et fournir des pièces justificatives à cet égard. La société est tenue d'exiger ces pièces et de les conserver par la suite dix ans après la radiation de l'associé ou de l'usufruitier du registre. Notre registre est fourni en format .doc (word), ce qui vous permet de l'adapter selon vos besoins. Une note explicative détaillée fournie avec le registre permet de comprendre les passages du document qui dépendent de votre choix et de les compléter de manière spécifique et adaptée selon la situation.",
      cta: "Télécharger un registre des parts sociales",
      cid: "rdp06",
    },
    {
      title: "Réquisition au registre du commerce pour Sàrl - nouveau but",
      subtitle:
        "Effectuez la mise à jour de l'inscription relative au but de votre société à responsabilité limitée auprès du registre du commerce, une fois que celui-ci a été modifié selon les exigences légales. Téléchargez une réquisition en format .doc (word), ainsi qu'une note explicative détaillée.",
      checklist: [
        "Réquisition au registre du commerce",
        "Note explicative détaillée",
      ],
      price: "Gratuit",
      description:
        "Modifier une inscription au registre du commerce nécessite l'envoi d'une réquisition claire et précise au registre du commerce, accompagnée des pièces justificatives nécessaires. Les documents à fournir avec une réquisition au registre du commerce varient selon l'inscription qui est demandée. Pour la modification du but d'une société à responsabilité limitée, il est nécessaire de démontrer que les statuts ont été modifiés selon les exigences légales, à savoir en la forme authentique, devant un notaire. Notre réquisition est fournie en format .doc (word), ce qui vous permet de l'adapter selon vos besoins. Une note explicative détaillée fournie avec la réquisition permet de comprendre les passages du document qui dépendent de votre choix et de les compléter de manière spécifique et adaptée selon la situation.",
      cta: "Télécharger une réquisition au registre du commerce",
      cid: "rar06",
    },
    {
      title: "Réquisition au registre du commerce pour Sàrl - nouveau siège",
      subtitle: "TODO",
      checklist: [
        "Réquisition au registre du commerce",
        "Note explicative détaillée",
      ],
      price: "Gratuit",
      description: "TODO",
      cta: "Télécharger une réquisition au registre du commerce",
      cid: "mx11",
    },
    {
      title: "Réquisition au registre du commerce pour Sàrl - nouvel associé",
      subtitle: "TODO",
      checklist: [
        "Réquisition au registre du commerce",
        "Note explicative détaillée",
      ],
      price: 50,
      description: "TODO",
      cta: "Télécharger une réquisition au registre du commerce",
      cid: "cet20",
    },
    {
      title:
        "Réquisition au registre du commerce pour Sàrl - nouvel associé-gérant",
      subtitle: "TODO",
      checklist: ["TODO"],
      price: 50,
      description: "TODO",
      cta: "Télécharger une réquisition au registre du commerce",
      cid: "cenb24",
    },
    {
      title:
        "Réquisition au registre du commerce pour Sàrl - nouvelle raison sociale",
      subtitle: "TODO",
      checklist: ["TODO"],
      price: 50,
      description: "TODO",
      cta: "Télécharger une réquisition au registre du commerce",
      cid: "reso26",
    },
  ];

  if (cid.includes("everything")) {
    return contracts;
  }

  for (const c of contracts) {
    if (c.cid.includes(cid)) {
      return c;
    }
  }
  return null;
};

// class for custom errors on requests
export const checkoutFactory = (
  prest,
  price,
  title,
  subtitle,
  text,
  checkboxes,
  cid = null
) => {
  return {
    prest: prest,
    price: price,
    title: title,
    subtitle: subtitle,
    text: text,
    checkboxes: checkboxes,
    cid: cid,
  };
};

// called for telephone checkout
export const telephonePrest = () => {
  return checkoutFactory(
    "meeting",
    100,
    "Entretien téléphonique",
    "Contacter un avocat",
    "Soumettez vos questions et vos problématiques par téléphone à un avocat. Fixez un rendez-vous directement sur notre site selon vos disponibilités pour un entretien téléphonique personnalisé de 30 à 40 minutes.",
    [
      "Entretien direct avec un avocat",
      "Prise de rendez-vous facilitée",
      "Conseils personnalisés",
    ]
  );
};

// called for meeting checkout
export const meetingPrest = () => {
  return checkoutFactory(
    "meeting",
    150,
    "Entretien en personne",
    "Contacter un avocat",
    "Soumettez vos questions et vos problématiques en personne à un avocat. Fixez un rendez-vous directement sur notre site selon vos disponibilités pour un entretien personnalisé avec un avocat de 45 à 60 minutes.",
    [
      "Rendez-vous en personne avec un avocat",
      "Prise de rendez-vous facilitée",
      "Conseils personnalisés",
    ]
  );
};

// Login helpers
export const isBrowser = () => typeof window !== "undefined";

export const getToken = () =>
  isBrowser() && window.localStorage.getItem("legalifyToken")
    ? window.localStorage.getItem("legalifyToken")
    : "";

const setToken = (token) => window.localStorage.setItem("legalifyToken", token);

export const handleLogin = async (username, password) => {
  try {
    const request = {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    };
    console.log(request);
    let response = await fetch(
      "https://legalify-back.herokuapp.com" + "/auth",
      request
    );
    let jsonResponse = await response.json();
    console.log(jsonResponse);
    if (jsonResponse.success) {
      console.log("Helper Success");
      console.log(jsonResponse.data.token);
      let token = jsonResponse.data.token;
      setToken(token);
      return true;
    } else {
      console.log("Helper Login unsuccessfull");
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const isLoggedIn = () => {
  const token = getToken();
  return token != "";
};
export const logout = (callback) => {
  setToken("");
  callback();
};

export default helpers;
